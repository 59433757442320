<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div
        class="control"
        v-if="
          companyPaymentPlanModules &&
          companyPaymentPlanModules.purchase.getArticlesInventory === 'S'
        "
      >
        <b-tooltip
          v-if="
            (articlesPaginated.length === 0 ||
              (warehouses.length <= 1 &&
                companyPaymentPlanModules &&
                companyPaymentPlanModules.main.importWarehousesArticles ===
                  'S' &&
                permissions &&
                permissions.main.importWarehousesArticles === 'S')) &&
            type === 0
          "
          label="Primero debes agregar artículos y almacenes al sistema o esperar a que el sistema cargue estos datos, solo se puede transferir artículos si se tiene más de un almacén"
          multilined
        >
          <b-button type="is-primary" disabled
            >Importar inventario en almacenes</b-button
          >
        </b-tooltip>
        <b-button
          type="is-primary"
          @click="startImport()"
          v-if="
            articlesPaginated.length > 0 &&
            warehouses.length > 1 &&
            companyPaymentPlanModules &&
            companyPaymentPlanModules.main.importWarehousesArticles === 'S' &&
            permissions &&
            permissions.main.importWarehousesArticles === 'S' &&
            type === 0
          "
          class="special-margin"
          >Importar inventario en almacenes</b-button
        >
      </div>

      <div
        class="control special-margin"
        v-if="
          (companyPaymentPlanModules &&
            companyPaymentPlanModules.purchase.transferArticles === 'S' &&
            permissions &&
            permissions.purchase.transferArticles === 'S' &&
            type === 0) ||
          (companyPaymentPlanModules &&
            companyPaymentPlanModules.purchase.transferArticlesPending ===
              'S' &&
            permissions &&
            permissions.purchase.transferArticlesPending &&
            type === 0)
        "
      >
        <b-tooltip
          v-if="
            (articlesPaginated.length === 0 || warehouses.length <= 1) &&
            type === 0
          "
          label="Primero debes agregar artículos y almacenes al sistema o esperar a que el sistema cargue estos datos, solo se puede transferir artículos si se tiene más de un almacén"
          multilined
        >
          <b-button type="is-primary" disabled class="special-margin"
            >Transferir artículos entre almacenes</b-button
          >
        </b-tooltip>
        <b-button
          type="is-primary"
          @click="openTransferArticle()"
          v-if="
            articlesPaginated.length > 0 && warehouses.length > 1 && type === 0
          "
          class="special-margin"
          >Transferir artículos entre almacenes</b-button
        >
      </div>

      <div
        class="control"
        v-if="
          (companyPaymentPlanModules &&
            companyPaymentPlanModules.purchase.addArticlesQuantity === 'S' &&
            permissions &&
            permissions.purchase.addArticlesQuantity === 'S' &&
            type === 0) ||
          (companyPaymentPlanModules &&
            companyPaymentPlanModules.purchase.addArticlesQuantityPending ===
              'S' &&
            permissions &&
            permissions.purchase.addArticlesQuantityPending === 'S' &&
            type === 0)
        "
      >
        <b-tooltip
          v-if="
            (articlesPaginated.length === 0 || warehouses.length === 0) &&
            type === 0
          "
          label="Primero debes agregar artículos y almacenes al sistema o esperar a que el sistema cargue estos datos"
          multilined
        >
          <b-button type="is-primary" disabled
            >Ajustes de inventario en almacén</b-button
          >
        </b-tooltip>
        <b-button
          type="is-primary"
          @click="openAddArticleQuantity()"
          v-if="articlesPaginated.length > 0 && warehouses.length && type === 0"
          >Ajustes de inventario en almacén</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="startExport()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.main.exportWarehousesArticles === 'S' &&
            permissions &&
            permissions.main.exportWarehousesArticles === 'S' &&
            type === 0
          "
          >Exportar inventario en almacenes</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="startResetSalesInventory()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.purchase.resetSalesInventory === 'S' &&
            permissions &&
            permissions.purchase.resetSalesInventory === 'S' &&
            type === 0
          "
          class="special-margin"
          >Recalcular inventario de ventas</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
      <div class="global-lists-filters-container"></div>
    </div>

    <div class="table-header">
      Cantidad de Artículos:
      <b>{{ articlesPaginatedTotal }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="articlesPaginated"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      ref="WarehousesTable"
      :checked-rows.sync="checkedWarehouses"
      paginated
      backend-pagination
      :total="articlesPaginatedTotal"
      :per-page="perPage"
      @page-change="
        (page) => {
          this.currentPage = page;
          this.reloadArticlesInformation();
        }
      "
      :opened-detailed="defaultOpenedDetails"
      :selected.sync="selected"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Información de ${row.CLAVE_ART}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      detail-key="_id"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="asc"
      checkbox-position="left"
      default-sort="CLAVE_ART"
      scrollable
    >
      <template #empty>
        <div class="has-text-centered">No hay almacenes or árticulos</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          :sortable="column.sortable"
          :visible="column.display"
          v-bind="column"
          :key="column.field"
          :label="column.label"
          :searchable="column.searchable"
        >
          <template v-if="column.searchable" #searchable="props">
            <b-field>
              <b-input
                v-model="searchFields[props.column.field]"
                @input="reloadArticlesInformation()"
              />
            </b-field>
          </template>

          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>

          <span>{{ props.row[column.field] }}</span>
        </b-table-column>

        <b-table-column
          v-for="column in warehousesColumns"
          :sortable="column.sortable"
          :visible="column.display"
          v-bind="column"
          :key="column.field"
          :label="column.label"
          :searchable="column.searchable"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>

          <span v-if="checkIfValid(props.row[column.field])">{{
            props.row[column.field]
          }}</span>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="content">
            <div v-if="type === 0">
              <div
                v-for="singlePresentationWarehouse in props.row
                  .defaultWarehouseArticles"
                v-bind:key="singlePresentationWarehouse._id"
                class="special-big-margin single-articles-prices"
              >
                <h3 class="title-expandable-item">
                  <strong
                    >Información general de almacén
                    {{ singlePresentationWarehouse.CLAVE_ALMACEN }}</strong
                  >
                  <strong><span class="global-form-hint-color"></span> </strong>
                </h3>

                <b-field grouped>
                  <b-field
                    label="Inventario minimo (Opcional)"
                    expanded
                    label-position="inside"
                  >
                    <b-input
                      placeholder="Ejemplo: 40"
                      expanded
                      icon="numeric-1-circle-outline"
                      type="number"
                      step="any"
                      v-model="singlePresentationWarehouse.MINIMO"
                    ></b-input>
                  </b-field>

                  <b-field
                    label="Inventario máximo (Opcional)"
                    expanded
                    label-position="inside"
                  >
                    <b-input
                      placeholder="Ejemplo: 40"
                      expanded
                      icon="numeric-8-box-multiple-outline"
                      type="number"
                      step="any"
                      v-model="singlePresentationWarehouse.MAXIMO"
                    ></b-input>
                  </b-field>
                </b-field>

                <div class="controls">
                  <b-button
                    type="is-primary"
                    @click="
                      saveWarehouseMinMax(
                        props.row,
                        singlePresentationWarehouse
                      )
                    "
                    >Guardar cambios</b-button
                  >
                </div>
              </div>

              <div
                v-for="singlePresentation in props.row.PRESENTACIONES"
                v-bind:key="singlePresentation._id"
                class="special-big-margin single-articles-prices"
              >
                <h3 class="title-expandable-item">
                  <strong>Presentación </strong>
                  <strong
                    ><span class="global-form-hint-color">{{
                      singlePresentation.NOMBRE
                    }}</span>
                  </strong>
                </h3>

                <b-field grouped class="special-margin">
                  <b-field
                    label="Valor de medida base del artículo (Requerido)"
                    expanded
                    label-position="inside"
                  >
                    <b-input
                      placeholder="Ejemplo: 40"
                      expanded
                      icon="numeric-1-circle-outline"
                      type="number"
                      step="any"
                      :value="singlePresentation.FACTOR1"
                      disabled
                    ></b-input>
                  </b-field>

                  <b-field
                    label="Cantidad de medidas base que equivale la presentación (Requerido)"
                    expanded
                    label-position="inside"
                  >
                    <b-input
                      placeholder="Ejemplo: 40"
                      expanded
                      icon="numeric-2-circle-outline"
                      type="number"
                      step="any"
                      :value="singlePresentation.FACTOR2"
                      disabled
                    ></b-input>
                  </b-field>
                </b-field>

                <div
                  v-for="singlePresentationWarehouse in props.row
                    .defaultWarehouseArticles"
                  v-bind:key="singlePresentationWarehouse._id"
                >
                  <h3 class="title-expandable-item">
                    <strong>Inventario de Presentación </strong>
                    <strong
                      ><span class="global-form-hint-color">{{
                        singlePresentation.NOMBRE
                      }}</span>
                      en almacén
                      <span class="global-form-hint-color">{{
                        singlePresentationWarehouse.CLAVE_ALMACEN
                      }}</span></strong
                    >
                  </h3>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Inventario en medida base del artículo (Requerido)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 40"
                        expanded
                        icon="numeric-1-circle-outline"
                        type="number"
                        step="any"
                        :value="singlePresentationWarehouse.CANTIDAD"
                        disabled
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Inventario en presentación (Requerido)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 40"
                        expanded
                        icon="numeric-2-circle-outline"
                        type="number"
                        step="any"
                        :value="
                          getPresentationInventory(
                            singlePresentationWarehouse,
                            singlePresentation
                          )
                        "
                        disabled
                      ></b-input>
                    </b-field>
                  </b-field>
                </div>
              </div>
            </div>
            <div v-if="type === 1">
              <p>No hay información que mostrar</p>
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
import ImportFileModal from "./ImportFileModal";
import TransferArticles from "./TransferArticles";
import AddArticlesQuantity from "./AddArticlesQuantity";
// @ is an alias to /src
import moment from "moment";
import { openConfirmationModalDanger } from "@/utils/component-fns";
import debounce from "lodash/debounce";

export default {
  name: "WarehousesArticlesListPaginated",
  components: {},
  props: ["type"],
  data() {
    //warehouses
    return {
      perPage: 50,
      checkedWarehouses: [],
      selected: {},
      currentPage: 1,
      searchFields: {
        CLAVE_ART: "",
        DESCRIPCION: "",
      },
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_ART",
          label: "Clave de articulo",
          sortable: false,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: false,
          searchable: true,
          display: true,
        },
        {
          field: "totalQuantity",
          label: "Inventario total",
          sortable: false,
          display: this.type === 0,
          searchable: false,
        },
        {
          field: "totalQuantitySales",
          label: "Inventario total de ventas",
          sortable: false,
          display: this.type === 1,
          searchable: false,
        },
      ],
      warehousesColumns: [],
      filterProvider: "",
      refreshLoading: false,
    };
  },
  async created() {
    moment.locale("es");
  },
  async mounted() {
    let columnsToAdd = [];
    for (const singleWarehouse of this.warehouses) {
      columnsToAdd.push({
        field: `almacen_${singleWarehouse.NOMBRE_ALM}`,
        label: `${singleWarehouse.NOMBRE_ALM}`,
        sortable: false,
        display: true,
        searchable: false,
      });
    }
    this.warehousesColumns = columnsToAdd;

    let allPromises = [];

    allPromises.push(
      this.$store.dispatch("GETARTICLESPAGINATED", {
        skip: this.perPage * (this.currentPage - 1),
        limit: this.perPage,
        keySearchInput: this.searchFields ? this.searchFields.CLAVE_ART : "",
        descriptionKeyInput: this.searchFields
          ? this.searchFields.DESCRIPCION
          : "",
        saleActive: true,
      })
    );

    await Promise.all(allPromises);
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(
        this.$store.dispatch("GETWAREHOUSES", {
          startDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 2
          ),
          endDate: new Date(),
        })
      );

      allPromises.push(
        this.$store.dispatch("GETARTICLESPAGINATED", {
          skip: this.perPage * (this.currentPage - 1),
          limit: this.perPage,
          keySearchInput: this.searchFields ? this.searchFields.CLAVE_ART : "",
          descriptionKeyInput: this.searchFields.DESCRIPCION,
          saleActive: true,
        })
      );

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    reloadArticlesInformation: debounce(async function (search) {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(
        this.$store.dispatch("GETARTICLESPAGINATED", {
          skip: this.perPage * (this.currentPage - 1),
          limit: this.perPage,
          keySearchInput: this.searchFields ? this.searchFields.CLAVE_ART : "",
          descriptionKeyInput: this.searchFields.DESCRIPCION,
          saleActive: true,
        })
      );

      await Promise.all(allPromises);

      this.refreshLoading = false;
    }, 300),
    startImport() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ImportFileModal,
        props: {
          importType: "warehouseArtciles",
          nameImport: "Inventario en almacenes",
          afterAction: "GETWAREHOUSESNOTRANSFERS",
        },
        hasModalCard: false,
        customClass: "import-provider-modal-class",
        trapFocus: true,
        width: "50%",
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     *
     * @desc gets the inventory in an item but with the presentation values
     * @param warehouse the current warehouse we are analyzing
     * @param presentation the current presentation of the article
     * @returns {number} the inventory of the used presentation
     */
    getPresentationInventory(warehouse, presentation) {
      if (presentation.ESMAYOR === "S") {
        if (warehouse.CANTIDAD > 0) {
          return warehouse.CANTIDAD / presentation.FACTOR2;
        }
        return 0;
      }
      return presentation.FACTOR2 * warehouse.CANTIDAD;
    },
    openTransferArticle() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: TransferArticles,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddArticleQuantity() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddArticlesQuantity,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async saveWarehouseMinMax(articleInformation, warehouseArticleInformation) {
      try {
        let response = await this.$store.dispatch("EDITWAREHOUSEMINMAX", {
          warehouseId: warehouseArticleInformation._id,
          articleId: articleInformation._id,
          newValues: {
            MINIMO: warehouseArticleInformation.MINIMO,
            MAXIMO: warehouseArticleInformation.MAXIMO,
          },
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se guardarón extosamente los cambios ",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al guardar los cambios en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }

        return;
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar los cambios en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    startExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación del inventario",
        message: `¿Desea confirmar la exportación de inventario a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: () => this.exportData(),
      });
    },
    async exportData() {
      const allArticles = await this.$store.dispatch("GETARTICLESNOMUTATION");
      const formattedAllArticles = allArticles.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        /**
         * Check if warehouse has products or add object with inventory in 0
         */
        let defaultWarehouseArticles = [];
        for (let singleWarehouse of this.warehouses) {
          let foundWarehouse = false;
          if (singleArticle.CANT_ALMACENES) {
            for (let singleArticleWarehouse of singleArticle.CANT_ALMACENES) {
              if (
                singleArticleWarehouse.CLAVE_ALMACEN === singleWarehouse._id
              ) {
                let finalQuantity = Number(singleArticleWarehouse.CANTIDAD);
                if (singleArticleWarehouse.CANTIDAD_VENTAS) {
                  finalQuantity += Number(
                    singleArticleWarehouse.CANTIDAD_VENTAS
                  );
                }
                if (singleArticleWarehouse.CANTIDAD_VIRTUAL) {
                  finalQuantity += Number(
                    singleArticleWarehouse.CANTIDAD_VIRTUAL
                  );
                }
                articleWithProps[`almacen_${singleWarehouse.NOMBRE_ALM}`] =
                  this.type === 0
                    ? singleArticleWarehouse.CANTIDAD
                    : finalQuantity;
                defaultWarehouseArticles.push({
                  CANTIDAD:
                    this.type === 0
                      ? singleArticleWarehouse.CANTIDAD
                      : finalQuantity,
                  NOMBRE_ALM: singleWarehouse.NOMBRE_ALM,
                  CLAVE_ALMACEN: singleWarehouse.CLAVE_ALMACEN,
                  MINIMO: singleArticleWarehouse.MINIMO,
                  MAXIMO: singleArticleWarehouse.MAXIMO,
                  _id: singleWarehouse._id,
                  display: true,
                  customSort: this.sortByWarehouseQuantity,
                });

                foundWarehouse = true;
                break;
              }
            }
            articleWithProps.totalQuantity = 0;
            for (let singleArticleWarehouse of singleArticle.CANT_ALMACENES) {
              articleWithProps.totalQuantity += Number(
                singleArticleWarehouse.CANTIDAD
              );
            }

            articleWithProps.totalQuantitySales = 0;
            for (let singleArticleWarehouse of singleArticle.CANT_ALMACENES) {
              articleWithProps.totalQuantitySales += Number(
                singleArticleWarehouse.CANTIDAD
              );

              if (singleArticleWarehouse.CANTIDAD_VENTAS) {
                articleWithProps.totalQuantitySales += Number(
                  singleArticleWarehouse.CANTIDAD_VENTAS
                );
              }
            }
          }

          if (!foundWarehouse) {
            articleWithProps[`almacen_${singleWarehouse.NOMBRE_ALM}`] = 0;
            defaultWarehouseArticles.push({
              CANTIDAD: 0,
              NOMBRE_ALM: singleWarehouse.NOMBRE_ALM,
              CLAVE_ALMACEN: singleWarehouse.CLAVE_ALMACEN,
              MINIMO: 0,
              MAXIMO: 0,
              _id: singleWarehouse._id,
              display: true,
              customSort: this.sortByWarehouseQuantity,
            });
          }
        }

        if (singleArticle.FE_ULTVTA) {
          articleWithProps.lastSaleFormat = moment(
            singleArticle.FE_ULTVTA
          ).format("DD-MM-YYYY HH:mm");
        }

        articleWithProps.defaultWarehouseArticles = defaultWarehouseArticles;
        return articleWithProps;
      });

      let warehouseInventory = [];
      for (let singleArticle of formattedAllArticles) {
        // Fill object with needed values
        for (const singleInventory of singleArticle.defaultWarehouseArticles) {
          let newObjectExport = {};

          newObjectExport["CLAVE DEL ALMACEN (Requerido)"] =
            singleInventory.CLAVE_ALMACEN;

          newObjectExport["CLAVE DEL ARTICULO (Requerido)"] =
            singleArticle.CLAVE_ART;

          newObjectExport["INVENTARIO (Opcional)"] = singleInventory.CANTIDAD;

          newObjectExport["MINIMO (Requerido)"] = singleInventory.MINIMO
            ? singleInventory.MINIMO
            : 0;

          newObjectExport["MAXIMO (Opcional)"] = singleInventory.MAXIMO
            ? singleInventory.MAXIMO
            : 0;

          warehouseInventory.push(newObjectExport);
        }
      }
      let payload = {
        data: warehouseInventory,
        name: "inventario-almacenes-" + moment().format("l").toString(),
      };
      this.$store.dispatch("EXPORTCSV", payload);
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    startResetSalesInventory(extraHoursInformation) {
      this.startCancelEvent(
        "danger",
        () => {
          this.resetSalesInventory();
        },
        "Reiniciar inventario de ventas"
      );
    },
    async resetSalesInventory() {
      try {
        this.$store.commit("SET_LOADING", true);
        let response = await this.$store.dispatch("RESETSALESINVENVTORY", {});
        if (response === "Success") {
          this.$store.commit("SET_LOADING", false);
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message:
              "Hemos recibido con éxito la información!! Seguimos trabajando para recalcular el almacen de ventas, se te notificará una vez terminado el proceso. Este proceso por lo regular tarda un par de horas, mientras tanto puedes seguir disfrutando de tu experiencia! 😃",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: 'Hubo un error al recalcular el almacen de ventas"',
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          this.$store.commit("SET_LOADING", false);
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al recalcular el almacen de ventas",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        this.$store.commit("SET_LOADING", false);
      }
    },
  },
  watch: {
    /**
     * @desc Select default opened details, to open a specific warehouse
     */
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    warehouses() {
      let columnsToAdd = [];
      for (const singleWarehouse of this.warehouses) {
        columnsToAdd.push({
          field: `almacen_${singleWarehouse.NOMBRE_ALM}`,
          label: `${singleWarehouse.NOMBRE_ALM}`,
          sortable: true,
          display: true,
          searchable: true,
        });
      }
      this.warehousesColumns = columnsToAdd;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES.map((singleWarehouse) => {
        return { ...singleWarehouse };
      });
    },
    articlesPaginated() {
      return this.$store.getters.ARTICLESPAGINATED.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        /**
         * Check if warehouse has products or add object with inventory in 0
         */
        let defaultWarehouseArticles = [];
        for (let singleWarehouse of this.warehouses) {
          let foundWarehouse = false;
          if (singleArticle.CANT_ALMACENES) {
            for (let singleArticleWarehouse of singleArticle.CANT_ALMACENES) {
              if (
                singleArticleWarehouse.CLAVE_ALMACEN === singleWarehouse._id
              ) {
                let finalQuantity = Number(singleArticleWarehouse.CANTIDAD);
                if (singleArticleWarehouse.CANTIDAD_VENTAS) {
                  finalQuantity += Number(
                    singleArticleWarehouse.CANTIDAD_VENTAS
                  );
                }
                if (singleArticleWarehouse.CANTIDAD_VIRTUAL) {
                  finalQuantity += Number(
                    singleArticleWarehouse.CANTIDAD_VIRTUAL
                  );
                }
                articleWithProps[`almacen_${singleWarehouse.NOMBRE_ALM}`] =
                  this.type === 0
                    ? singleArticleWarehouse.CANTIDAD
                    : finalQuantity;
                defaultWarehouseArticles.push({
                  CANTIDAD:
                    this.type === 0
                      ? singleArticleWarehouse.CANTIDAD
                      : finalQuantity,
                  NOMBRE_ALM: singleWarehouse.NOMBRE_ALM,
                  CLAVE_ALMACEN: singleWarehouse.CLAVE_ALMACEN,
                  MINIMO: singleArticleWarehouse.MINIMO,
                  MAXIMO: singleArticleWarehouse.MAXIMO,
                  _id: singleWarehouse._id,
                  display: true,
                  customSort: this.sortByWarehouseQuantity,
                });

                foundWarehouse = true;
                break;
              }
            }
            articleWithProps.totalQuantity = 0;
            for (let singleArticleWarehouse of singleArticle.CANT_ALMACENES) {
              articleWithProps.totalQuantity += Number(
                singleArticleWarehouse.CANTIDAD
              );
            }

            articleWithProps.totalQuantitySales = 0;
            for (let singleArticleWarehouse of singleArticle.CANT_ALMACENES) {
              articleWithProps.totalQuantitySales += Number(
                singleArticleWarehouse.CANTIDAD
              );

              if (singleArticleWarehouse.CANTIDAD_VENTAS) {
                articleWithProps.totalQuantitySales += Number(
                  singleArticleWarehouse.CANTIDAD_VENTAS
                );
              }
            }
          }

          if (!foundWarehouse) {
            articleWithProps[`almacen_${singleWarehouse.NOMBRE_ALM}`] = 0;
            defaultWarehouseArticles.push({
              CANTIDAD: 0,
              NOMBRE_ALM: singleWarehouse.NOMBRE_ALM,
              CLAVE_ALMACEN: singleWarehouse.CLAVE_ALMACEN,
              MINIMO: 0,
              MAXIMO: 0,
              _id: singleWarehouse._id,
              display: true,
              customSort: this.sortByWarehouseQuantity,
            });
          }
        }

        if (singleArticle.FE_ULTVTA) {
          articleWithProps.lastSaleFormat = moment(
            singleArticle.FE_ULTVTA
          ).format("DD-MM-YYYY HH:mm");
        }

        articleWithProps.defaultWarehouseArticles = defaultWarehouseArticles;
        return articleWithProps;
      });
    },
    articlesPaginatedTotal() {
      return this.$store.getters.ARTICLESPAGINATEDTOTAL;
    },
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        let providerWithProps = { ...singleProvider };
        return providerWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_PRV > b.NOMBRE_PRV ? 1 : -1;
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
