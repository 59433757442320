<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Inventario en almacenes </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Inventario" icon="numeric">
        <WarehousesArticlesList
          :type="0"
          v-if="articlesPaginatedTotal < 9000 && articlesPaginatedTotal"
        />
        <WarehousesArticlesListPaginated
          :type="0"
          v-if="articlesPaginatedTotal > 9000 || !articlesPaginatedTotal"
        />
      </b-tab-item>
      <b-tab-item label="Inventario de ventas" icon="counter">
        <WarehousesArticlesList
          :type="1"
          v-if="articlesPaginatedTotal < 9000 && articlesPaginatedTotal"
        />
        <WarehousesArticlesListPaginated
          :type="1"
          v-if="articlesPaginatedTotal > 9000 || !articlesPaginatedTotal"
        />
      </b-tab-item>
      <b-tab-item label="Todos los movimientos" icon="swap-horizontal">
        <AllWarehosesMovementsList></AllWarehosesMovementsList>
      </b-tab-item>
      <b-tab-item
        label="Movimientos pendientes de confirmar"
        icon="warehouse"
        :visible="companyPaymentPlanModules.purchase.transferArticles === 'S'"
      >
        <WarehousesTransfers type="2"></WarehousesTransfers>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import WarehousesArticlesList from "../components/WarehousesArticlesList";
import WarehousesArticlesListPaginated from "../components/WarehousesArticlesListPaginated";
import AllWarehosesMovementsList from "../components/AllWarehosesMovementsList";
import WarehousesTransfers from "../components/WarehousesTransfers";
export default {
  name: "WarehousesArticlesComponents",
  components: {
    WarehousesArticlesList,
    WarehousesArticlesListPaginated,
    AllWarehosesMovementsList,
    WarehousesTransfers,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "inventory");
    if (
      this.companyPaymentPlanModules.humanResources.getWarehouseMovements ===
      "N"
    ) {
      this.personalTab = 1;
    }
  },
  async created() {
    let allPromises = [];

    allPromises.push(
      this.$store.dispatch("GETWAREHOUSES", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 2
        ),
        endDate: new Date(),
      })
    );

    allPromises.push(
      this.$store.dispatch("GETARTICLESPAGINATED", {
        skip: this.perPage * (this.currentPage - 1),
        limit: this.perPage,
        keySearchInput: this.searchFields ? this.searchFields.CLAVE_ART : "",
        descriptionKeyInput: this.searchFields.DESCRIPCION,
        saleActive: true,
      })
    );

    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
    articlesPaginatedTotal() {
      return this.$store.getters.ARTICLESPAGINATEDTOTAL;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
